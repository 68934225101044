var image = document.getElementsByClassName('parallax');
new simpleParallax(image, {
	delay: .6,
	transition: 'cubic-bezier(0,0,0,1)'
});



mediaQuery = (function() {

  // Same as in partials/_variables.sass
  // var screenXs = 480; // Not used
  var screenSm = 576;
  var screenMd = 768;
  var screenLg = 1200;

  var screenXsMax = screenSm - 1;
  var screenSmMax = screenMd - 1;
  var screenMdMax = screenLg - 1;

  return {

    /**
     * match() returns true
     * if there's any match to the media query
     *
     * @param {String} str
     * @return {Boolean}
     *
     * ex1: mediaQuery.match('xs')
     * ex2: mediaQuery.match('md lg')
     */
    match: function(str) {
      var arr = str.split(/[\s,|]+/);

      // If there's any match, return true.
      for (var i = 0; i < arr.length; i++) {
        switch (arr[i]) {
          case 'xs':
            if (window.matchMedia('(max-width: ' + screenXsMax + 'px)').matches)
              return true;
            break;
          case 'sm':
            if (window.matchMedia('(min-width: ' + screenSm + 'px) and (max-width: ' + screenSmMax + 'px)').matches)
              return true;
            break;
          case 'md':
            if (window.matchMedia('(min-width: ' + screenMd + 'px) and (max-width: ' + screenMdMax + 'px)').matches)
              return true;
            break;
          case 'lg':
            if (window.matchMedia('(min-width: ' + screenLg + 'px)').matches)
              return true;
            break;
        }
      }
      return false;
    }
  }
})();


if(mediaQuery.match('lg')){
  var prevScrollpos = window.pageYOffset;
  window.onscroll = function() {
  var currentScrollPos = window.pageYOffset;
    if (prevScrollpos > currentScrollPos) {
      document.getElementById("header").style.top = "0";
      $( "#header" ).addClass('hover');
    } else {
      document.getElementById("header").style.top = "-7rem";
    }
    prevScrollpos = currentScrollPos;
    if (currentScrollPos == 0){
      $( "#header" ).removeClass('hover');
    }
  }
}

$('.collapse').on('shown.bs.collapse', function () {
  $(this).previousSibling.addClass('opened');
})




  // hamburger: add classes to animate
  $('#js-bootstrap-offcanvas').on('show.bs.offcanvas', function () {
    jQuery('.hamburger .lines').addClass('active');
    jQuery('.hamburger').addClass('active');
    jQuery('.hamburger .lines').removeClass('not-active');
  });
  $('#js-bootstrap-offcanvas').on('hide.bs.offcanvas', function () {
    jQuery('.hamburger .lines').addClass('not-active');
    jQuery('.hamburger').removeClass('active');
    jQuery('.hamburger .lines').removeClass('active');
  });

  $('.navbar-offcanvas-close').click(function() {
    $("#js-bootstrap-offcanvas").trigger("offcanvas.close");
  });
  $('.mo-links a').click(function() {
    $("#js-bootstrap-offcanvas").trigger("offcanvas.close");
  });